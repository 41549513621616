import { openDB } from 'idb';
import gamesEnV1 from "./json/games-en.json";
import gamesFrV1 from "./json/games-fr.json";

const DB_NAME = `spelling-lola`;
const DB_VERSION = 1;

const loadGamesInDb = async (tb, games) => {
    const dbConnect = openDB(DB_NAME, DB_VERSION);
    games.forEach( async game =>{
        console.log(`[INFO] db.js > adding ${tb}`, game);
        const res = (await dbConnect).add(tb, game);
    });
    (await dbConnect).close()
};

const dbPromise = openDB(DB_NAME, DB_VERSION, {
    upgrade(upgradeDb, oldVersion, newVersion, tx) {
        console.log(`Upgrading to version ${DB_VERSION}`);

        let gamesEn,gamesFr, savedGame;

        switch (oldVersion) {
            case 0:
                gamesEn = upgradeDb.createObjectStore('spQ_games_en', {keyPath: 'uid'});
                loadGamesInDb(`spQ_games_en`, gamesEnV1);

                gamesFr = upgradeDb.createObjectStore('spQ_games_fr', {keyPath: 'uid'});
                loadGamesInDb(`spQ_games_fr`, gamesFrV1);
           }
    }
});

export const idb = {
    get: async (store, key) => await (await dbPromise).get(store, key),
    getAll: async (store) => await ( await dbPromise).getAll(store),
    getAllFromIndex: {
        eq: async (store, index, val) => await ( await dbPromise).getAllFromIndex(store, index, val),
        gt: async (store, index, min) => await ( await dbPromise).getAllFromIndex(store, index, IDBKeyRange.lowerBound(min)),
        lt: async (store, index, max) => await ( await dbPromise).getAllFromIndex(store, index, IDBKeyRange.upperBound(max)),
        bt: async (store, index, [min , max]) => await ( await dbPromise).getAllFromIndex(store, index, IDBKeyRange.bound(min, max)),
        startWith: async (store, index, val) => { // Does not return the right dataset
            let res = [];
            let cursor = await ( await dbPromise ).transaction(store).store.index(index).openCursor();
            while (true) {
                res.push(cursor.value);
                cursor = await cursor.continue();
                if (!cursor) break;
            }
            return res;
        }
    },
    add: async (store, data) => (await dbPromise).add(store, data),
    set:  async (store, data, key) => (await dbPromise).put(store, data, key),
    delete: async (store, key) => await (await dbPromise).delete(store, key),
    count:  async (store) => await (await dbPromise).count(store),
    save: async (store, array) => { console.group(`INFO: ibd.save`);console.log(array);console.groupEnd(); array.forEach( async record => (await dbPromise).put(store, record) )}
};
