import { readable, writable, derived } from 'svelte/store';
import { getRandomInt, shuffle } from "../js/helper";
import { openDB } from 'idb';
import { idb } from '../db';

// const savedGame = await idb.get(`spQ_saved_game`, `current`);

const games = [
    {
        key_letter      : `g`,
        extra_letters   : [`a`, `e`, `l`, `m`, `n`, `t` ],
        played          : 0,
        last_played     : null,
        max_score       : 0
    },
    {
        key_letter      : `g`,
        extra_letters   : [`a`, `e`, `l`, `m`, `n`, `t` ],
        played          : 0,
        last_played     : null,
        max_score       : 0,
        lang            : `en`
    }
];

{}

/* 
** Main stores
*/
const initFromLocalStorage = (field, defaultValue) => {
    const cachedValue = localStorage.getItem(field);
    if ( cachedValue ) {
        try {
            return JSON.parse(cachedValue)
        } catch (error) {
            return cachedValue;
        }
    } else {
        return defaultValue;
    }
};
const persistInLocalStorage = (field, value) => {
    try {
        localStorage.setItem(field, JSON.stringify(value));
    } catch (error) {
        localStorage.setItem(field, value);
    }
};

// LANGUAGE
export const lang = writable (initFromLocalStorage(`spQ_lang`, `en`));
// UID
export const uid = writable (initFromLocalStorage(`spQ_game_uid`, false));
// CURRENT_GAME
export const currentGame = writable (initFromLocalStorage(`spQ_game_current`, false));
// WORD
export const word = writable (initFromLocalStorage(`spQ_word`, []));
// FOUND
export const foundCached = writable (initFromLocalStorage(`spQ_game_found`, []));
// SCORE
export const score = writable (initFromLocalStorage(`spQ_game_score`, 0));
// SHUFFLE_COUNT
export const shuffleCount = writable(0);

/** 
 * PERSISTENCE IN LOCALSTORAGE
 * **/
lang.subscribe( value => persistInLocalStorage(`spQ_lang`, value) );
uid.subscribe( value => persistInLocalStorage(`spQ_game_uid`, value) );
foundCached.subscribe( value => persistInLocalStorage(`spQ_game_found`, value) );
score.subscribe( value => persistInLocalStorage(`spQ_game_score`, value) );
/* 
** Derived stores
*/

export const shuffledLetters = derived (
    [currentGame,shuffleCount],
    ([$currentGame]) => {
        if($currentGame){
            const draw = shuffle($currentGame.extra_letters);
            return draw;
        }
        return false;
        
    }
);

export const rank = derived (
    [currentGame,score],
    ([$currentGame,$score]) => {
        let print;
        if ( $score === 0 ) {
            print = `Newbie`;
        } else
        if ( $score > 0 && $score < Math.round($currentGame.maxScore * .01) ) {
            print = `First steps`;
        } else
        if ( $score < Math.round($currentGame.maxScore * .025) ) {
            print = `Good start`;
        } else
        if ( $score < Math.round($currentGame.maxScore * .0625) ) {
            print = `Moving up`;
        } else
        if ( $score < Math.round($currentGame.maxScore * .125) ) {
            print = `Solid`;
        } else
        if ( $score < Math.round($currentGame.maxScore * .25) ) {
            print = `Balling`;
        } else
        if ( $score < Math.round($currentGame.maxScore * .33) ) {
            print = `Great`;
        } else
        if ( $score < Math.round($currentGame.maxScore * .50) ) {
            print = `Amazing`;
        } else
        if ( $score < Math.round($currentGame.maxScore * .66) ) {
            print = `To the moon`;
        } else
        if ( $score < Math.round($currentGame.maxScore * .75) ) {
            print = `Spelling queen`;
        }
        return print;
    }
);

export const tableGames = derived (
    [lang],
    ($lang) => { return $lang == `en` ? `spQ_games_en` : `spQ_games_fr` ;}
);