<script>    
    // Import App foundations (Svelte critical elements, Tailwind, etc.)
    // Svelte
    import { onMount } from 'svelte';
    import { fade, fly, scale } from "svelte/transition";
    import { backOut, elasticOut, elasticInOut } from "svelte/easing";
    // Tailwind CSS
	import Tailwind from './screens/tailwind.svelte';
    // Import stores
    import { uid, word, score, currentGame, shuffledLetters, shuffleCount, rank, foundCached, lang, tableGames } from "./store/game";
    // Import helper functions
    import { getRandomInt, shuffle } from "./js/helper";
    // Import db connection
    import { idb } from "./db";
    import { get } from 'svelte/store';
    // import selectedGames from './selection.json';

    // Import Screens

    /*
    ** PROP definition
    */

    /*
    ** CONST variables definition
    */
    const APP_VERSION = `0.0.1`;
    const date = (new Date).toLocaleDateString(`en-US`, { day: `numeric`, month: `short`, year: `numeric`}); 

    /*
    ** LET variables definition
    */
    let selectedGame;
    let rejected;
    let accepted;
    let found = [];
    let error={};
    let cheer={};

    let states = {
        letters : {
            one : {
                state: false
            },
            two : {
                state: false
            },
            three : {
                state: false
            },
            four : {
                state: false
            },
            five : {
                state: false
            },
            six : {
                state: false
            },
            seven : {
                state: false
            },
        },
        word    : { state: false },
        listWord : { state: false }
    }

    /*
    ** Registers the ServiceWorker for full PWA functionalities
    */

    /*
    ** onMount() 
    */
    onMount( async () => {
        // If a game is cached then load saved details
        if ( $uid ) {
            currentGame.set(await idb.get($tableGames, $uid));
            found=$foundCached;
            console.log($currentGame, $uid);
        } else { // If not then pick a new game
            await newGame($lang);
        }
        if($tableGames) idb.getAll($tableGames);
        /* */
        // rejected = localStorage.getItem(`spQ_rejected`) ? JSON.parse(localStorage.getItem(`spQ_rejected`)) : [];
        // accepted = localStorage.getItem(`spQ_accepted`) ? JSON.parse(localStorage.getItem(`spQ_accepted`)) : [];
        // console.log(rejected,accepted);
        // pickGame();
    })

    const words = [
        {
            key         : `gene`,
            pts         : 1,
            length      : 4,
            bingo       : false
        },
        {
            key         : `manga`,
            pts         : 5,
            length      : 5,
            bingo       : false
        },
        {
            key         : `legal`,
            pts         : 5,
            length      : 5,
            bingo       : false
        },
        {
            key         : `tangle`,
            pts         : 6,
            length      : 6,
            bingo       : false
        },
        {
            key         : `magenta`,
            pts         : 7,
            length      : 7,
            bingo       : false
        },
        {
            key         : `gentleman`,
            pts         : 16,
            length      : 9,
            bingo       : true
        }
    ];

    /*
    ** Page specific logic
    */
    const addLetter = (letter, btnRef) => {
        states.letters[btnRef].state = true;
        setTimeout(()=>states.letters[btnRef].state = false, 500)
        word.set([...$word, letter]);
    };
    const removeLetter = () => {
        $word.pop()
        word.set($word);
    };
    const shuffleLetters = () => {
        [`one`,`two`,`three`,`five`,`six`,`seven`].forEach( btnRef => {
            states.letters[btnRef].state = true;
            setTimeout(()=>states.letters[btnRef].state = false, 500)
        })
        shuffleCount.set($shuffleCount++);
    };
    const validateAnswer = () => {
        const answer = $word.join(``);
        console.log(answer);
        const dupInFound = found.filter( entry => entry === answer );
        if( dupInFound.length > 0 ){
            errorToast(`Word already found`);
        } else {
            console.log( $currentGame.answers.words);
            const result = $currentGame.answers.words.filter( entry => entry === answer );
            if( result.length > 0 ){
                found=[...found, answer];
                foundCached.set(found);
                const total = getPoints(answer);
                console.log($score, total.pts, total.pts + $score);
                score.set(total.pts + $score);
                cheerToast(total);
                word.set([]);
            } else {
                errorToast(`Invalid word`)
            }
            
        }
    };

    const getPoints = answer => {
        let total = { pts: 0, isBingo: false};
        // Null handler in case of bug
        if ( !answer ) return total;
        // If the word is a bingo it gets 7 extra points
        if ( $currentGame.answers.bingos.filter( bingo => bingo === answer ).length > 0 ) total = {pts: total.pts + 7, isBingo: true};
        // If word has 4 letters only it gets one point
        if ( answer.length == 4 ) {
            total.pts++;
        } else { // Otherwise it gets a point per letter
            total.pts = total.pts + answer.length; 
        }
        return total;
    };

    const errorToast = message => {
        error={
            active:true,
            message:message
        };
        states.word.state = true;
        setTimeout(() => { word.set([]) ; }, 325)
        setTimeout(() => { error = {} ; states.word.state = false ; }, 850)
    };
    const cheerToast = (total) => {
        let message;
        if(total.isBingo) {
            message=`BINGO!`;
        } else {
            message=`Good!`;
        }
        cheer={
            active:true,
            message:message,
            pts:total.pts
        };
        setTimeout(() => { cheer = {} ; }, 600)
    };

    const newGame = async l => {
        if( l != $lang ) await lang.set(l);
        const maxGamesUid = await idb.count($tableGames);
        const min = 0;
        uid.set(Math.floor(Math.random() * (maxGamesUid - min) + min)); // The maximum is exclusive and the minimum is inclusive
        await currentGame.set(await idb.get($tableGames, $uid));
        word.set([]);
        found=[];
        foundCached.set(found);
        score.set(0);
        shuffleCount.set(0);
        console.log($currentGame, $uid);
    }

    const pickGame = () => {
        const random = Math.floor(Math.random() * selectedGames.length);
        selectedGame = selectedGames[random];
        if(rejected.indexOf(selectedGame.uid) > -1) pickGame()
        if(accepted.indexOf(selectedGame.uid) > -1) pickGame()
    };
    const reject = async () =>{
        rejected=[...rejected, selectedGame.uid];
        localStorage.setItem(`spQ_rejected`, JSON.stringify(rejected))
        pickGame();
    };
    const accept = async () =>{
        accepted=[...accepted, selectedGame.uid]
        localStorage.setItem(`spQ_accepted`, JSON.stringify(accepted))
        pickGame();
    };
</script>
<Tailwind/>
<main class="m-0 p-0 fixed top-0 left-0 bottom-0 right-0 flex flex-col justify-between bg-white">    
    <section id="nav" class="pt-2">
        <img src="/img/icons/crown.png" class="w-10 h-10 m-auto" alt="">
    </section>
    <section id="header" class="flex flex-col px-4 pb-4 border-b border-shade">
        <div>
            <span class="animate-shake font-interstate text-3xl">Spelling Queen</span><span class="pl-2 italic text-xs">{date}</span>
        </div>
        <span class="italic pl-2" on:click={()=>cheerToast(`Good!`)}>Made with ❤️ for Lola</span>
    </section>
    <section id="score" class="flex justify-between h-12 items-center mx-2">
        <span class="font-medium grow-0 shrink-0 w-32">{$rank}</span> 
        <div
            class:Newbie={$rank==`Newbie`}
            class:First_steps={$rank==`First steps`}
            class:Good_start={$rank==`Good start`}
            class:Moving_up={$rank==`Moving up`}
            class:Solid={$rank==`Solid`}
            class:Balling={$rank==`Balling`}
            class:Great={$rank==`Great`}
            class:Amazing={$rank==`Amazing`}
            class:To_the_moon={$rank==`To the moon`}
            class:Spelling_queen={$rank==`Spelling queen`} 
            class="flex grow font-medium items-center"
        >
                <span class="progressTick absolute transition-all duration-200 z-30 flex items-center justify-center w-6 h-6  rounded-md border border-navajowhite bg-navajowhite text-white  shadow-sm">{$score}</span>
                <span class:complete={$score >= Math.round($currentGame.maxScore * .01)} class="right-[58%] progressThreshold"> </span>
                <span class:complete={$score >= Math.round($currentGame.maxScore * .025)} class="right-[50.25%] progressThreshold"> </span>
                <span class:complete={$score >= Math.round($currentGame.maxScore * .0625)} class="right-[42.5%] progressThreshold"> </span>
                <span class:complete={$score >= Math.round($currentGame.maxScore * .125)} class="right-[35%] progressThreshold"> </span>
                <span class:complete={$score >= Math.round($currentGame.maxScore * .25)} class="right-[28%] progressThreshold"> </span>
                <span class:complete={$score >= Math.round($currentGame.maxScore * .33)} class="right-[21%] progressThreshold"> </span>
                <span class:complete={$score >= Math.round($currentGame.maxScore * .50)} class="right-[14.5%] progressThreshold"> </span>
                <span class:complete={$score >= Math.round($currentGame.maxScore * .66)} class="right-[8%]  progressThreshold"> </span>
                <span class:complete={$score >= Math.round($currentGame.maxScore * .75)} class="right-[2%] progressThreshold"> </span>
                <div class="absolute z-0 h-[1px] right-[2%] w-[56%] bg-shade"></div>
                <div class="progressBar absolute z-10 h-[3px] bg-navajowhite"></div>
        </div>
        
    </section>
    <section id="words" class="flex flex-row-reverse h-12 items-center justify-end p-2 mx-3 overflow-auto no-scrollbar border border-shade rounded-md" on:click|stopPropagation={()=>states.listWord.state=true}>
        {#if found.length === 0 }
            <span>No word found yet</span>
        {/if}
        
        {#each found as f }
            <span in:fly="{{y:50}}" class="capitalize mx-1">{f}</span>
        {/each}
        {#if error.active }
            <span in:fly="{{y:50, easing: elasticOut}}" out:fade id="errorToast" class="absolute z-20 bg-black/60 left-[30%] py-2 px-2 w-[40%] text-center text-sm text-white">   
                {error.message}
            </span>
        {/if}
        {#if cheer.active }
            <span in:fly="{{y:50, duration: 250, easing: backOut}}" out:fade id="cheerToast" class="absolute z-20 bg-white/90 border border-blueish left-[30%] py-2 px-2 w-[40%] text-center">   
                {cheer.message}
            </span>
            <span in:fly="{{y:100, duration: 800}}" out:fade id="cheerToastPts" class="absolute z-20 left-[70%] py-2 px-2 text-center italic font-bold">   
                +{cheer.pts}
            </span>
        {/if}
    </section>
    {#if states.listWord.state }
        <section transition:scale={{duration: 1000, easing: elasticInOut}} class="fixed z-40 w-full h-full px-4 bg-tomato text-white">
            <section class="flex flex-col h-full justify-between">
                <section class="flex space-x-6 space-y-4 flex-wrap">
                    <span class="text-lg pt-4 font-semibold">Words found:</span>
                    {#each found as f }
                        <span class="border-b">{f}</span>
                    {/each}
                </section>
                <div class="h-12 place-self-end grow-0" on:click|stopPropagation={()=>states.listWord.state=false}>
                    <img class="w-8" src="/img/icons/back.png" alt="" on:click|stopPropagation={()=>states.listWord.state=false}>
                </div>
            </section>
        </section>
    {/if}
    
    <section id="board" class="flex flex-col items-center justify-center grow">
        {#if $currentGame}
            <div class:shake={states.word.state} id="input" class="h-14 flex items-center uppercase text-4xl font-semibold" >
                {#each $word as letter}
                    <span class:isKey={letter==$currentGame.key_letter} out:fade >{letter}</span>
                {/each}
                    <span class="text-silver animate-blinking">_</span>
            </div>
            <section id="letters" class="h-4/6 w-60 flex justify-between space-x-2">
                <div class="flex flex-col  justify-center space-y-2">
                    <span class:animateBtn={states.letters.one.state} class="flex items-center justify-center w-20 h-20 uppercase bg-navajowhite drop-shadow-md rounded-md text-4xl font-semibold"  on:click={()=>addLetter($shuffledLetters[0], `one`)}>
                        {$shuffledLetters[0]}
                    </span>
                    <span class:animateBtn={states.letters.two.state} class="flex items-center justify-center w-20 h-20 uppercase bg-navajowhite drop-shadow-md rounded-md text-4xl font-semibold" on:click={()=>addLetter($shuffledLetters[1], `two` )}>
                        {$shuffledLetters[1]}
                    </span>
                </div>
                <div class="flex flex-col justify-center  space-y-2">
                    <span class:animateBtn={states.letters.three.state} class="flex items-center justify-center w-20 h-20 uppercase bg-navajowhite drop-shadow-md rounded-md text-4xl font-semibold" on:click={()=>addLetter($shuffledLetters[2], `three`)}>
                        {$shuffledLetters[2]}
                    </span>
                    <span class:animateBtn={states.letters.four.state} class="flex items-center justify-center w-20 h-20 uppercase bg-navajowhite drop-shadow-md rounded-md text-4xl font-semibold text-tomato" on:click={()=>addLetter($currentGame.key_letter[0], `four`)}>
                        {$currentGame.key_letter}
                    </span>
                    <span class:animateBtn={states.letters.five.state} class="flex items-center justify-center w-20 h-20 uppercase bg-navajowhite drop-shadow-md rounded-md text-4xl font-semibold" on:click={()=>addLetter($shuffledLetters[3], `five`)}>
                        {$shuffledLetters[3]}
                    </span>
                </div>
                <div class="flex flex-col justify-center  space-y-2">
                    <span class:animateBtn={states.letters.six.state} class="flex items-center justify-center w-20 h-20 uppercase bg-navajowhite drop-shadow-md rounded-md text-4xl font-semibold" on:click={()=>addLetter($shuffledLetters[4], `six`)}>
                        {$shuffledLetters[4]}
                    </span>
                    <span class:animateBtn={states.letters.seven.state} class="flex items-center justify-center w-20 h-20 uppercase bg-navajowhite drop-shadow-md rounded-md text-4xl font-semibold" on:click={()=>addLetter($shuffledLetters[5], `seven`)}>
                        {$shuffledLetters[5]}
                    </span>
                </div>
            </section>
            <div id="actions" class="flex items-center space-x-4 grow">
                <span class="p-4 flex items-center justify-center underline underline-offset-8 decoration-slate-300" on:click={()=>removeLetter()}>
                    Delete
                </span>
                <div class="flex items-center justify-center border rounded-full w-12 h-12" on:click|stopPropagation={shuffleLetters}>
                    <img src="/img/icons/refresh.png" class="w-8" alt="">
                </div>
                <span class="p-4 flex items-center justify-center underline underline-offset-8 decoration-slate-300" on:click|stopPropagation={validateAnswer}>
                    Enter
                </span>
            </div>
        {/if}
    </section>
    <section class=" border-b flex items-end justify-end border-silver h-12 pr-8 space-x-5">
        {#if $lang == `en`}
            <img src="/img/icons/en-active.png" class="w-10" alt="" on:click={()=>newGame(`en`)}>
        {:else}
            <img src="/img/icons/en.png" class="w-10" alt="" on:click={()=>newGame(`en`)}>
        {/if}
        {#if $lang == `fr`}
            <img src="/img/icons/fr-active.png" class="w-10" alt="" on:click={()=>newGame(`fr`)}>
        {:else}
            <img src="/img/icons/fr.png" class="w-10" alt="" on:click={()=>newGame(`fr`)}>
        {/if}
    </section>
</main>
<style lang="postcss">
    .animateBtn { @apply animate-wiggle }
    .shake { @apply animate-shake }
    .progressThreshold { @apply absolute z-20 w-2 h-2 border border-shade bg-shade rounded-full shadow-sm}
    .complete { @apply border-navajowhite bg-navajowhite }
    .isKey { @apply text-tomato}

    .Newbie > .progressTick{ @apply right-[56%]}
    .First_steps > .progressTick{ @apply right-[55%]}
    .Good_start > .progressTick{ @apply right-[48.25%]}
    .Moving_up > .progressTick{ @apply right-[40.5%]}
    .Solid > .progressTick{ @apply right-[33%]}
    .Balling > .progressTick{ @apply right-[26%]}
    .Great > .progressTick{ @apply right-[19%]}
    .Amazing > .progressTick{ @apply right-[12.5%]}
    .To_the_moon > .progressTick{ @apply right-[6%]}
    .Spelling_queen > .progressTick{ @apply right-[1%]}

    .Newbie > .progressBar{ @apply right-[58%] w-0}
    .First_steps > .progressBar{ @apply right-[58%] w-0}
    .Good_start > .progressBar{ @apply right-[50.25%] w-[7.75%]}
    .Moving_up > .progressBar{ @apply right-[42.5%] w-[15.5%]}
    .Solid > .progressBar{ @apply right-[35%] w-[23%]}
    .Balling > .progressBar{ @apply right-[28%] w-[30%]}
    .Great > .progressBar{ @apply right-[21%] w-[37%]}
    .Amazing > .progressBar{ @apply right-[14.5%] w-[43.5%]}
    .To_the_moon > .progressBar{ @apply right-[8%] w-[50%]}
    .Spelling_queen > .progressBar{ @apply right-[2%] w-[56%]}
</style>